<template>
  <d-remote-monitoring-indicator-trends
    :user="user"
    :role="role"
    @on-select-template="onSelectTemplateHandler"
    @on-create-link="onCreateLink"
  />
</template>

<script>

export default {
  name: 'RemoteMonitoringIndicatorTrends',
  page: {
    title: 'CRM Doctis - Дис. мониторинг',
  },
  computed: {
    user() {
      return this.$store.state.Auth.user;
    },
    role() {
      return this.$store.state.Auth.role;
    },
  },
  methods: {
    onSelectTemplateHandler(id) {
      this.$router.push(`/remote-monitoring/indicator-trends/view/${id}/`);
    },
    onCreateLink() {
      this.$router.push('/remote-monitoring/indicator-trends/create/');
    },
  },
};
</script>
